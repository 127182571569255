import { render, staticRenderFns } from "./Home-adminShopComponent.vue?vue&type=template&id=2a1b0abc&scoped=true"
import script from "./Home-adminShopComponent.vue?vue&type=script&lang=js"
export * from "./Home-adminShopComponent.vue?vue&type=script&lang=js"
import style0 from "./Home-adminShopComponent.vue?vue&type=style&index=0&id=2a1b0abc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a1b0abc",
  null
  
)

export default component.exports